import * as Sentry from "@sentry/vue";
import { Dedupe } from '@sentry/integrations';
import Vue from "vue";

Sentry.init({
  Vue,
  dsn: window.SENTRY_DSN,
  release: window.SENTRY_RELEASE_NAME,
  environment: window.SENTRY_ENVIRONMENT,
  maxBreadcrumbs: 50,
  attachStacktrace: true,
  integrations: [new Dedupe()],
  defaultIntegrations: Sentry.defaultIntegrations.filter(({ name }) => name !== 'TryCatch'),
});
Sentry.setUser({"login": window.SENTRY_USER_NAME});
Sentry.setUser({"user": window.SENTRY_USER_NAME});
Sentry.setTag("logger", "browser");
window.Sentry = Sentry

declare global {
  interface Window {
    SENTRY_USER_NAME: string
    SENTRY_RELEASE_NAME: string
    SENTRY_DSN: string
    SENTRY_ENVIRONMENT: string
    Sentry: typeof Sentry
  }
}

export default Sentry
